import { render, staticRenderFns } from "./HowWeWorkLab.vue?vue&type=template&id=8965dd6c"
import script from "./HowWeWorkLab.vue?vue&type=script&lang=js"
export * from "./HowWeWorkLab.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OrganismsHeader: require('/src/components/organisms/Header.vue').default,OrganismsModules: require('/src/components/organisms/Modules.vue').default,OrganismsFooter: require('/src/components/organisms/Footer.vue').default})
