
import pageMixin from '@/mixins/page';
import transitionsMixin from '@/mixins/transitions';
import pageQuery from '@/gql/queries/pages/howWeWorkLab271.gql';
import { COLOR_SCHEME_DARK } from '~/constants/General';

export default {
  mixins: [pageMixin, transitionsMixin],
  inheritAttrs: false,
  asyncData({ $cms, i18n, store }) {
    if (process.server) store.commit('nav/colorScheme', COLOR_SCHEME_DARK);
    return $cms.query({
      query: pageQuery,
      variables: { site: i18n.locale },
      mandatoryKeys: ['entry'],
    });
  },
  head() {
    return this.formatHead(this.entry?.seomatic);
  },
  created() {
    if (process.client) {
      this.$store.commit('nav/colorScheme', COLOR_SCHEME_DARK);
    }
  },
};
